import { SVGProps } from 'react';

export interface SvgProps extends SVGProps<SVGSVGElement> {
  title: string,
}

const Svg = ({ title, children, ...restProps }: SvgProps) => (
  <svg
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    role="img"
    aria-labelledby="title"
    /* eslint-disable react/jsx-props-no-spreading */
    {...restProps}
  >
    <title>
      {title}
    </title>
    {children}
  </svg>
);

export default Svg;
