import Svg from 'components/Svg';

const CloseIcon = () => (
  <Svg
    viewBox="0 -960 960 960"
    title="Close"
    height="24"
    width="24"
  >
    <path
      d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"
      fill="currentColor"
    />
  </Svg>
);

export default CloseIcon;
