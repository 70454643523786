import {
  useLayoutEffect,
  useState,
  lazy,
} from 'react';
import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { withSuspense } from 'utils/helpers';
import { PATHNAMES } from 'utils/constants';

const CookiesConsentBanner = lazy(() => import('components/CookiesConsentBanner'));
const Footer = lazy(() => import('components/Footer'));
const Header = lazy(() => import('components/Header'));

const StyledLayout = styled.div`
  width: 100%;
  min-height: 100vh;
`;

const Layout = () => {
  const { pathname } = useLocation();
  const [isLandingPage, setIsLandingPage] = useState(false);

  useLayoutEffect(() => {
    if (pathname.includes(`${PATHNAMES.MusicLandingPage}/`)) {
      setIsLandingPage(true);
    }
  }, [pathname]);

  return (
    <>
      <ScrollRestoration />
      {withSuspense(<CookiesConsentBanner />)}
      <StyledLayout>
        {!isLandingPage && withSuspense(<Header />)}
        <main>
          <Outlet />
        </main>
      </StyledLayout>
      {!isLandingPage && withSuspense(<Footer />)}
    </>
  );
};

export default Layout;
