import { SERVICES } from 'utils/constants';
import Svg from 'components/Svg';

const SoundCloud = () => (
  <Svg
    viewBox="0 0 100 100"
    title={SERVICES.SoundCloud}
    fontSize="1.8em"
  >
    <path
      d="M50.0006 3.48276e-10C43.4344 -2.45061e-05 36.9325 1.29325 30.8661 3.80597C24.7998 6.3187 19.2878 10.0017 14.6448 14.6446C10.0018 19.2875 6.31877 24.7995 3.80602 30.8658C1.29326 36.9321 -2.34726e-05 43.4339 3.19516e-10 50C-2.34726e-05 56.5661 1.29326 63.0679 3.80602 69.1342C6.31877 75.2005 10.0018 80.7125 14.6448 85.3554C19.2878 89.9983 24.7998 93.6813 30.8661 96.194C36.9325 98.7068 43.4344 100 50.0006 100C63.2613 99.9998 75.9789 94.7318 85.3556 85.355C94.7323 75.9782 100 63.2606 100 50C100 36.7394 94.7323 24.0218 85.3556 14.645C75.9789 5.2682 63.2613 0.000242867 50.0006 3.48269e-10V3.48276e-10ZM58.8513 31.0294C72.7728 31.0294 74.1595 45.0412 74.1595 45.0412C74.1595 45.0412 75.5461 44.1832 77.9329 44.1832C80.3134 44.1832 86.4682 46.5316 86.4682 53.3439C86.4682 62.1947 76.7788 62.3495 76.7788 62.3495H46.5619V37.461C47.5682 35.4934 50.7486 31.0294 58.8513 31.0294V31.0294ZM38.3044 36.294V62.356H33.3499V37.2166C34.7175 36.6748 36.369 36.3439 38.3044 36.294ZM39.9558 36.3459C42.0138 36.5459 43.7943 37.2037 44.9104 37.7261V62.3498H39.9558V36.3459ZM30.0468 39.2678V62.3563H26.7438V44.4225C27.2406 42.9258 28.2148 40.9257 30.0468 39.2678ZM21.2284 45.8285C22.0477 45.8285 22.7892 45.8931 23.4408 45.9771V62.3552H22.305C21.5566 62.3552 20.8346 62.2527 20.1378 62.0785V45.9512C20.4991 45.893 20.8608 45.8285 21.2284 45.8285V45.8285ZM16.8348 47.3831V60.5046C14.8479 58.9886 13.5318 56.6728 13.5318 54.0536C13.5318 51.2668 14.8866 48.8604 16.8348 47.3831V47.3831Z"
      fill="currentColor"
    />
  </Svg>
);

export default SoundCloud;
