import { SERVICES } from 'utils/constants';
import Svg from 'components/Svg';

const Bandcamp = () => (
  <Svg
    viewBox="0 0 100 100"
    title={SERVICES.Bandcamp}
    fontSize="1.8em"
  >
    <path
      d="M50.0006 3.48279e-10C43.4344 -2.45063e-05 36.9325 1.29326 30.8661 3.80601C24.7998 6.31877 19.2878 10.0018 14.6448 14.6448C10.0018 19.2877 6.31877 24.7998 3.80602 30.8661C1.29326 36.9325 -2.42995e-05 43.4344 3.42426e-10 50.0005C0.000234934 63.2613 5.26824 75.9789 14.6451 85.3556C24.022 94.7323 36.7398 100 50.0006 100C63.2612 99.9998 75.9786 94.7319 85.3552 85.3552C94.7319 75.9786 99.9998 63.2611 100 50.0005C100 36.7397 94.7323 24.0221 85.3556 14.6452C75.9789 5.26825 63.2613 0.000242869 50.0006 3.48283e-10V3.48279e-10ZM26.0742 38.3334H52.9192H56.5689V46.7955H56.6342C57.6252 45.1934 59.7072 44.1966 61.5704 44.1966C66.7924 44.1966 69.3263 48.1729 69.3263 53.015C69.3263 57.4655 67.084 61.6666 62.1766 61.6666C59.9395 61.6666 57.5302 61.1265 56.4383 58.9428H56.3793V61.2209H52.9191V38.3334L40.381 61.6666H13.5413L26.0742 38.3334ZM78.988 44.1966C82.8392 44.1966 86.1083 46.1247 86.4585 50.1954H82.8035C82.5127 48.1778 81.0411 47.0808 79.0235 47.0808C77.1306 47.0808 74.4717 48.0777 74.4717 53.1097C74.4717 55.869 75.6884 58.7824 78.8631 58.7824C80.9756 58.7824 82.4475 57.3696 82.8035 54.996H86.4585C85.7821 59.2922 83.1238 61.6666 78.8631 61.6666C73.6709 61.6666 70.8166 57.9816 70.8166 53.1097C70.8166 48.1133 73.5406 44.1966 78.988 44.1966V44.1966ZM61.0906 47.0808C58.0168 47.0808 56.444 49.4245 56.444 52.9493C56.444 56.2784 58.1414 58.7824 61.0906 58.7824C64.4255 58.7824 65.6715 55.8332 65.6715 52.9493C65.6715 49.9348 64.0991 47.0808 61.0906 47.0808V47.0808Z"
      fill="currentColor"
    />
  </Svg>
);

export default Bandcamp;
