import { css } from 'styled-components';

const LinkTransitions = css`  
  &:hover {
    opacity: ${({ theme }) => (theme.opacities.eighty)};
  }

  &:active {
    opacity: ${({ theme }) => (theme.opacities.sixty)};
  }
`;

export default LinkTransitions;
