import { DefaultTheme } from 'styled-components';
import { PAGE_WIDTH } from 'utils/constants';

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: {
      primary: string,
      primaryVariation: string,
      secondary: string,
      tertiary: string,
      tertiaryVariation1: string,
      tertiaryVariation2: string,
    },
    fonts: {
      typeface: string,
      sizes: {
        xs: string,
        s: string,
        m: string,
        l: string,
        xl: string,
        xxl: string,
        xxxl: string,
      }
    },
    spacings: {
      xs: string,
      s: string,
      m: string,
      l: string,
      xl: string,
    },
    opacities: {
      eighty: number,
      sixty: number,
    },
    widths: {
      pageWidth: string,
      textWidth: string,
    }
  }
}

const theme: DefaultTheme = {
  colors: {
    primary: '#0A0000',
    primaryVariation: '#0D0000',
    secondary: '#FFF8F8',
    tertiary: '#181515',
    tertiaryVariation1: '#A8A4A4',
    tertiaryVariation2: '#131010',
  },
  fonts: {
    typeface: '\'Courier New\', Courier, monospace',
    sizes: {
      xs: '1.2rem',
      s: '1.4rem',
      m: '1.6rem',
      l: '1.8rem',
      xl: '2.0rem',
      xxl: '2.4rem',
      xxxl: '3rem',
    },
  },
  spacings: {
    xs: '0.5rem',
    s: '1rem',
    m: '2rem',
    l: '3rem',
    xl: '5rem',
  },
  opacities: {
    eighty: 0.8,
    sixty: 0.6,
  },
  widths: {
    pageWidth: `${PAGE_WIDTH}px`,
    textWidth: '600px',
  },
};

export default theme;
