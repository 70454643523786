import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';
import LinkTransitions from 'styles/LinkTransitions';

const cookiesConsentBannerBreakpoint1 = '630px';
const cookiesConsentBannerBreakpoint2 = '400px';
const cookiesConsentBannerBreakpoint3 = '240px';

export const StyledBannerCookiesConsentBannerBackground = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 998;
  background-color: ${({ theme }) => (theme.colors.primary)};
  opacity: 0.75;
`;

export const StyledCookiesConsentBanner = styled.div`
  position: fixed;
  z-index: 999;
  margin-left: ${({ theme }) => (theme.spacings.m)};
  margin-right: ${({ theme }) => (theme.spacings.m)};
  top: 50%;
  transform: translate(0, -50%);
  background-color: ${({ theme }) => (theme.colors.tertiary)};
  -moz-box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.25);
  padding: ${({ theme }) => (theme.spacings.m)};
  max-width: 600px;

  h2 {
    margin-bottom: ${({ theme }) => (theme.spacings.m)};
  
    @media only screen and (max-width: ${cookiesConsentBannerBreakpoint3}) {
      margin-top: ${({ theme }) => (theme.spacings.l)};
    }
  }

  a {
    text-decoration: underline;
    display: inline-block;
    font-weight: bold;
  }

  a, p {
    font-size: ${({ theme }) => (theme.fonts.sizes.s)};

    @media only screen and (max-width: ${BREAKPOINTS.SMALLER_FONT_SIZE}) {
      font-size: ${({ theme }) => (theme.fonts.sizes.xs)};
    }
  }

  ul {
    margin-top: ${({ theme }) => (theme.spacings.m)};
    margin-bottom: ${({ theme }) => (theme.spacings.s)};
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (max-width: ${cookiesConsentBannerBreakpoint1}) {
      flex-direction: column;
    }
  }

  li {
    display: flex;
  }

  label {
    display: flex;
    align-items: center;
    margin-bottom: ${({ theme }) => (theme.spacings.s)};
    font-size: ${({ theme }) => (theme.fonts.sizes.m)};
    @media only screen and (max-width: ${BREAKPOINTS.SMALLER_FONT_SIZE}) {
      font-size: ${({ theme }) => (theme.fonts.sizes.s)};
    }
  }
`;

export const StyledButtons = styled.div`
  display: flex;
  flex-direction: row;
  
  @media only screen and (max-width: ${cookiesConsentBannerBreakpoint2}) {
    flex-direction: column;
  }

  button {
    padding: ${({ theme }) => (theme.spacings.s)};
    font-size: ${({ theme }) => (theme.fonts.sizes.l)};

    @media only screen and (max-width: ${BREAKPOINTS.SMALLER_FONT_SIZE}) {
      font-size: ${({ theme }) => (theme.fonts.sizes.m)};
    }
  }
`;

export const Spacer = styled.div`
  width: ${({ theme }) => (theme.spacings.s)};
  height: ${({ theme }) => (theme.spacings.s)};
`;

export const StyledPrimaryButton = styled.button`
  background-color: ${({ theme }) => (theme.colors.secondary)};
  color: ${({ theme }) => (theme.colors.primary)};
  border: 1px solid ${({ theme }) => (theme.colors.secondary)};
  flex-grow: 1;
`;

export const StyledSecondaryButton = styled.button`
  background-color: ${({ theme }) => (theme.colors.primary)};
  color: ${({ theme }) => (theme.colors.secondary)};
  border: 1px solid ${({ theme }) => (theme.colors.secondary)};
  flex-grow: 1;
`;

export const StyledClose = styled.button`
  color: ${({ theme }) => (theme.colors.secondary)};
  display: block;
  background: none;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: ${({ theme }) => (theme.spacings.xs)};
  margin-right: ${({ theme }) => (theme.spacings.xs)};
  cursor: pointer;
  ${LinkTransitions}
`;
