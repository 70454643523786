import { Service } from 'types';
import AmazonMusic from 'components/ThirdPartyLogos/AmazonMusic';
import AppleMusic from 'components/ThirdPartyLogos/AppleMusic';
import Bandcamp from 'components/ThirdPartyLogos/Bandcamp';
import Beatport from 'components/ThirdPartyLogos/Beatport';
import Facebook from 'components/ThirdPartyLogos/Facebook';
import Instagram from 'components/ThirdPartyLogos/Instagram';
import Mixcloud from 'components/ThirdPartyLogos/Mixcloud';
import SoundCloud from 'components/ThirdPartyLogos/SoundCloud';
import Spotify from 'components/ThirdPartyLogos/Spotify';
import YouTube from 'components/ThirdPartyLogos/YouTube';

export const SERVICES = {
  AmazonMusic: 'Amazon Music' as const,
  AppleMusic: 'Apple Music' as const,
  Bandcamp: 'Bandcamp' as const,
  Beatport: 'Beatport' as const,
  Facebook: 'Facebook' as const,
  Instagram: 'Instagram' as const,
  Mixcloud: 'Mixcloud' as const,
  SoundCloud: 'SoundCloud' as const,
  Spotify: 'Spotify' as const,
  YouTube: 'YouTube' as const,
};

export const SOCIAL_MEDIA_SERVICES: Service[] = [
  {
    icon: <AmazonMusic />,
    name: SERVICES.AmazonMusic,
    url: 'https://music.amazon.com/artists/B0105LAN1E/joel-forsberg',
  },
  {
    icon: <AppleMusic />,
    name: SERVICES.AppleMusic,
    url: 'https://music.apple.com/us/artist/joel-forsberg/1003394298',
  },
  {
    icon: <Bandcamp />,
    name: SERVICES.Bandcamp,
    url: 'https://joelforsberg.bandcamp.com/',
  },
  {
    icon: <Beatport />,
    name: SERVICES.Beatport,
    url: 'https://www.beatport.com/artist/joel-forsberg/488717',
  },
  {
    icon: <Facebook />,
    name: SERVICES.Facebook,
    url: 'https://www.facebook.com/forsbergjoel',
  },
  {
    icon: <Instagram />,
    name: SERVICES.Instagram,
    url: 'https://www.instagram.com/_joelforsberg/',
  },
  {
    icon: <Mixcloud />,
    name: SERVICES.Mixcloud,
    url: 'https://www.mixcloud.com/joelforsberg/',
  },
  {
    icon: <SoundCloud />,
    name: SERVICES.SoundCloud,
    url: 'https://soundcloud.com/joelforsberg',
  },
  {
    icon: <Spotify />,
    name: SERVICES.Spotify,
    url: 'https://open.spotify.com/artist/5L3kqpAo1Qmae2kEmBRU8R',
  },
  {
    icon: <YouTube />,
    name: SERVICES.YouTube,
    url: 'https://www.youtube.com/@joel.forsberg',
  },
];

const socialMediaServicesToRemove: string[] = [SERVICES.Facebook, SERVICES.Instagram];
export const MUSIC_SERVICES: Service[] = SOCIAL_MEDIA_SERVICES
  .map(({ url, ...rest }) => rest)
  .filter((service) => !(socialMediaServicesToRemove.includes(service.name)));

export const PAGE_WIDTH = 800;

export const BREAKPOINTS = {
  SMALLER_FONT_SIZE: '500px',
};

export const DOCUMENT_IDS = {
  CookiesPolicy: 'cookies-policy' as const,
  PrivacyPolicy: 'privacy-policy' as const,
};

export const PATHNAMES = {
  CookiesPolicy: '/cookies-policy' as const,
  MailingList: '/mailing-list' as const,
  Music: '/music' as const,
  MusicLandingPage: '/m' as const,
  PrivacyPolicy: '/privacy-policy' as const,
};

export const COPYRIGHT_NOTICE = `\u00A9 ${new Date().getFullYear()} Joel Forsberg`;
