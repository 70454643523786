import { ApolloClient, InMemoryCache } from '@apollo/client';
import { isPreviewApi } from 'utils/helpers';

const accessToken = isPreviewApi ? process.env.REACT_APP_PREVIEW_API_ACCESS_TOKEN
  : process.env.REACT_APP_CONTENT_DELIVERY_API_ACCESS_TOKEN;

const uri = `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_SPACE_ID}`;
const authorization = `Bearer ${accessToken}`;

const client = new ApolloClient({
  uri,
  cache: new InMemoryCache(),
  headers: {
    authorization,
  },
});

export default client;
