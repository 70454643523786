import { SERVICES } from 'utils/constants';
import Svg from 'components/Svg';

const Beatport = () => (
  <Svg
    viewBox="0 0 100 100"
    title={SERVICES.Beatport}
    fontSize="1.8em"
  >
    <path
      d="M49.9994 4.00031e-10C36.7387 0.000239317 24.0211 5.26819 14.6444 14.645C5.26769 24.0218 -4.37521e-05 36.7393 3.97471e-10 50C-5.28363e-05 63.2606 5.26768 75.9782 14.6444 85.355C24.0211 94.7318 36.7387 99.9998 49.9994 100C56.5656 100 63.0675 98.7068 69.1339 96.194C75.2002 93.6813 80.7122 89.9983 85.3552 85.3554C89.9982 80.7125 93.6812 75.2005 96.194 69.1342C98.7067 63.0679 100 56.5661 100 50C100 43.4339 98.7067 36.9321 96.194 30.8658C93.6812 24.7995 89.9982 19.2875 85.3552 14.6446C80.7122 10.0017 75.2002 6.31869 69.1339 3.80597C63.0675 1.29324 56.5656 -2.62639e-05 49.9994 4.00031e-10V4.00031e-10ZM42.4245 21.8719H50.6002V37.3522C50.6002 42.1026 48.9113 46.1186 45.6231 49.4068L45.2601 49.7706C48.1364 47.1209 52.0628 45.5213 56.2639 45.5213C65.5245 45.5276 72.6013 52.8797 72.6013 61.7832C72.6013 70.824 65.3865 78.128 56.2565 78.128C47.2155 78.128 40.0009 71.0025 40.0009 61.7832C40.0009 57.4447 41.6416 53.6073 44.2502 50.731L33.1978 61.7832L27.3971 55.9826L39.8641 43.6535C41.5528 41.9647 42.4243 39.7754 42.4243 37.3522L42.4245 21.8719ZM56.2567 53.0177C51.2797 53.0177 47.4017 57.129 47.4017 61.7833C47.4017 66.5748 51.3278 70.6393 56.2567 70.6393C61.3709 70.6393 65.2086 66.4856 65.2086 61.7833C65.2086 56.9436 61.1924 53.0177 56.2567 53.0177V53.0177Z"
      fill="currentColor"
    />
  </Svg>
);

export default Beatport;
