import styled from 'styled-components';

const Checkbox = styled.input`
  appearance: none;
  -webkit-appearance: none;
  display: flex;
  padding: 1px;
  border: 2px solid ${({ theme }) => (theme.colors.tertiaryVariation1)};
  background-color: ${({ theme }) => (theme.colors.tertiary)};
  cursor: pointer;
  margin-right: ${({ theme }) => (theme.spacings.s)};
  width: ${({ theme }) => (theme.fonts.sizes.xxxl)};
  height: ${({ theme }) => (theme.fonts.sizes.xxxl)};

  ::before {
    content: "";
    width: ${({ theme }) => (theme.fonts.sizes.xxl)};
    height: ${({ theme }) => (theme.fonts.sizes.xxl)};
    clip-path: polygon(12% 54%, 25% 41%, 37% 54%, 76% 16%, 88% 29%, 37% 79%, 12% 54%);
    transform: scale(0);
    background-color: ${({ theme }) => (theme.colors.secondary)};
  }

  :checked::before {
    transform: scale(1);   
  }

  :focus {
    outline: 0;
  }

  :disabled {
    background-color: ${({ theme }) => (theme.colors.tertiaryVariation1)};
    cursor: default;
  }
`;

export default Checkbox;
