import { SERVICES } from 'utils/constants';
import Svg from 'components/Svg';

const AppleMusic = () => (
  <Svg
    viewBox="0 0 100 100"
    title={SERVICES.AppleMusic}
    fontSize="1.8em"
  >
    <path
      d="M49.9994 4.00023e-10C36.7387 0.000239317 24.0211 5.26819 14.6444 14.645C5.26769 24.0218 -4.65714e-05 36.7393 3.66672e-10 50C-5.0748e-05 63.2606 5.26768 75.9782 14.6444 85.355C24.0211 94.7318 36.7387 99.9998 49.9994 100C56.5656 100 63.0675 98.7068 69.1339 96.194C75.2002 93.6813 80.7122 89.9983 85.3552 85.3554C89.9982 80.7125 93.6812 75.2005 96.194 69.1342C98.7067 63.0679 100 56.5661 100 50C100 43.4339 98.7067 36.9321 96.194 30.8658C93.6812 24.7995 89.9982 19.2875 85.3552 14.6446C80.7122 10.0017 75.2002 6.31869 69.1339 3.80597C63.0675 1.29324 56.5656 -2.62639e-05 49.9994 4.00031e-10V4.00023e-10ZM68.7797 20.2151C69.9595 20.2482 70.9587 21.2147 70.9587 22.4609V63.6931C70.9587 67.7386 70.3564 73.756 61.3522 73.756H61.3512C58.365 73.756 54.1735 71.9212 54.1735 67.6291C54.1735 63.3371 56.8491 60.8257 60.6115 60.0952C62.1093 59.8027 63.7447 59.4738 65.1784 59.1725C66.7035 58.8625 67.7892 57.5286 67.7892 55.9761V36.2229C67.7892 35.2823 66.9319 34.5799 66.0182 34.7717L42.9499 39.4193C42.0275 39.611 41.3705 40.4154 41.3705 41.3561V69.5099C41.3705 73.5463 40.7587 79.5459 31.7813 79.5459C28.8042 79.5459 24.6305 77.7192 24.6305 73.4362C24.6305 69.1533 27.2889 66.6511 31.0513 65.9206C32.6586 65.6106 34.4392 65.2448 35.9552 64.9344C37.2428 64.6693 38.1553 63.5376 38.1461 62.2225L37.9185 29.5567C37.9028 27.6937 39.2057 26.0872 41.032 25.7219L68.2646 20.2603C68.4379 20.2249 68.6103 20.2107 68.7796 20.2154L68.7797 20.2151Z"
      fill="currentColor"
    />
  </Svg>
);

export default AppleMusic;
