import { ReactNode, Suspense } from 'react';
import {
  OriginalArtist,
  Release,
  ReleaseTitle,
  Stores,
  StoreUrls,
} from 'types';
import { MUSIC_SERVICES } from './constants';

export const getReleaseHeading = (
  artist: OriginalArtist,
  releaseTitle: ReleaseTitle,
  alwaysReturnArtist = false,
) => {
  if (!releaseTitle) {
    return '';
  }

  if (artist) {
    return `${artist} - ${releaseTitle}`;
  }

  if (alwaysReturnArtist) {
    return `Joel Forsberg - ${releaseTitle}`;
  }

  return releaseTitle;
};

export const isPreviewApi = process.env.REACT_APP_API === 'preview';

export const parseReleaseCollection = (
  releaseCollection: {
    releaseCollection: {
      items: [],
    },
  },
) => {
  const parsedReleaseCollection: Release[] = releaseCollection?.releaseCollection?.items ?? [];
  return parsedReleaseCollection;
};

export const parseStoreUrls = (stores?: StoreUrls) => {
  const storeUrls = stores?.urls ?? {};

  return MUSIC_SERVICES
    .filter((musicService) => (
      Object.keys(storeUrls).includes(musicService.name)
    ))
    .map((musicService) => (
      {
        ...musicService,
        url: storeUrls[musicService.name as keyof Stores],
      }
    ));
};

export const getReleaseDate = (date: Date) => (
  new Date(date).toLocaleString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })
);

export const withSuspense = (component: ReactNode) => (
  <Suspense fallback={null}>
    {component}
  </Suspense>
);

export const getViewportWidth = () => (
  Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
);
