import { SERVICES } from 'utils/constants';
import Svg from 'components/Svg';

const Instagram = () => (
  <Svg
    viewBox="0 0 100 100"
    title={SERVICES.Instagram}
    fontSize="1.8em"
  >
    <path
      d="M50 3.13917e-10C43.4339 -2.32655e-05 36.9321 1.29325 30.8658 3.80598C24.7995 6.3187 19.2875 10.0017 14.6446 14.6446C10.0017 19.2875 6.3187 24.7995 3.80598 30.8658C1.29325 36.9321 -2.35757e-05 43.4339 3.51228e-10 50C-2.46097e-05 56.5661 1.29325 63.0679 3.80597 69.1342C6.3187 75.2005 10.0017 80.7125 14.6446 85.3554C19.2875 89.9983 24.7995 93.6813 30.8658 96.194C36.9321 98.7068 43.4339 100 50 100C56.5661 100 63.0679 98.7068 69.1342 96.194C75.2005 93.6813 80.7125 89.9983 85.3554 85.3554C89.9983 80.7125 93.6813 75.2005 96.194 69.1342C98.7068 63.0679 100 56.5661 100 50C100 43.4339 98.7068 36.9321 96.194 30.8658C93.6813 24.7995 89.9983 19.2875 85.3554 14.6446C80.7125 10.0017 75.2005 6.3187 69.1342 3.80598C63.0679 1.29325 56.5661 -2.32655e-05 50 3.13907e-10V3.13917e-10ZM37.7226 18.8952H62.2785C72.6445 18.8952 81.1048 27.3555 81.1048 37.7215V62.2774C81.1048 72.6434 72.6445 81.1037 62.2785 81.1037H37.7226C27.3566 81.1037 18.8963 72.6434 18.8963 62.2774V37.7215C18.8963 27.3555 27.3566 18.8952 37.7226 18.8952V18.8952ZM37.7226 23.8066C30.0105 23.8066 23.8077 30.0094 23.8077 37.7215V62.2774C23.8077 69.9895 30.0105 76.1934 37.7226 76.1934H62.2785C69.9906 76.1934 76.1934 69.9895 76.1934 62.2774V37.7215C76.1934 30.0094 69.9906 23.8066 62.2785 23.8066H37.7226ZM66.3706 30.3555C68.1803 30.3555 69.6456 31.8197 69.6456 33.6294C69.6456 35.4391 68.1803 36.9033 66.3706 36.9033C64.5609 36.9033 63.0967 35.4391 63.0967 33.6294C63.0967 31.8197 64.5609 30.3555 66.3706 30.3555V30.3555ZM50 33.6294C59.0102 33.6294 66.3706 40.9897 66.3706 50C66.3706 59.0103 59.0102 66.3706 50 66.3706C40.9898 66.3706 33.6294 59.0103 33.6294 50C33.6294 40.9897 40.9898 33.6294 50 33.6294V33.6294ZM50 38.5408C43.6435 38.5408 38.5408 43.6436 38.5408 50C38.5408 56.3564 43.6435 61.4592 50 61.4592C56.3565 61.4592 61.4603 56.3564 61.4603 50C61.4603 43.6436 56.3565 38.5408 50 38.5408Z"
      fill="currentColor"
    />
  </Svg>
);

export default Instagram;
