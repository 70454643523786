import {
  createContext,
  useMemo,
  useState,
  PropsWithChildren,
} from 'react';

interface LoadingContextProps {
  isLoading: boolean,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
}

export const LoadingContext = createContext<LoadingContextProps>({
  isLoading: true,
  setIsLoading: () => null,
});

export const LoadingHandler = ({ children }: PropsWithChildren) => {
  const [isLoading, setIsLoading] = useState(true);
  const value = useMemo(() => ({ isLoading, setIsLoading }), [isLoading]);

  return (
    <LoadingContext.Provider value={value}>
      {children}
    </LoadingContext.Provider>
  );
};
