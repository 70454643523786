import { SERVICES } from 'utils/constants';
import Svg from 'components/Svg';

const Facebook = () => (
  <Svg
    viewBox="0 0 100 100"
    title={SERVICES.Facebook}
    fontSize="1.8em"
  >
    <path
      d="M49.9995 3.98797e-10C36.7387 0.000240463 24.0211 5.29349 14.6444 14.7153C5.26769 24.1372 -4.63621e-05 36.9158 3.06031e-10 50.2401C0.00547314 62.2596 4.29931 73.8787 12.1023 82.9889C19.9052 92.0991 30.7001 98.0966 42.5279 99.8929V63.615H30.1556V50.4111H42.5279V41.6207C42.5279 27.0699 49.5842 20.6876 61.6149 20.6876C67.376 20.6876 70.4269 21.1145 71.8695 21.3096V32.8401H63.6583C58.5539 32.8401 56.7691 37.7094 56.7691 43.1914V50.4111H71.7403L69.7056 63.6139H56.7691V100C68.7431 98.356 79.7193 92.4099 87.6669 83.262C95.6146 74.114 99.9958 62.3833 100 50.2401C100 43.6425 98.7067 37.1095 96.194 31.014C93.6812 24.9186 89.9982 19.3802 85.3552 14.7149C80.7122 10.0497 75.2002 6.34904 69.1339 3.82425C63.0675 1.29946 56.5656 -2.62861e-05 49.9995 3.98794e-10V3.98797e-10Z"
      fill="currentColor"
    />
  </Svg>
);

export default Facebook;
