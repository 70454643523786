import { createGlobalStyle } from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';
import LinkTransitions from './LinkTransitions';

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html {
    font-size: 62.5%;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  body {
    font-size: 1.6rem;
    font-family: ${({ theme }) => (theme.fonts.typeface)};
    background: ${({ theme }) => `linear-gradient(to bottom, ${theme.colors.primaryVariation}, ${theme.colors.primary})`};
    background-color: ${({ theme }) => (theme.colors.primary)};
    color: ${({ theme }) => (theme.colors.secondary)};
  }

  #root {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  main {
    flex: 1;
    width: 100%;
    max-width: ${({ theme }) => (theme.widths.pageWidth)};
    margin: 0 auto;
  }

  h1 {
    font-size: ${({ theme }) => (theme.fonts.sizes.xxxl)};
    font-weight: bold;
    text-align: center;

    @media only screen and (max-width: ${BREAKPOINTS.SMALLER_FONT_SIZE}) {
      font-size: ${({ theme }) => (theme.fonts.sizes.xxl)};
    }
  }

  h2 {
    font-size: ${({ theme }) => (theme.fonts.sizes.xxl)};
    font-weight: bold;
    text-align: center;

    @media only screen and (max-width: ${BREAKPOINTS.SMALLER_FONT_SIZE}) {
      font-size: ${({ theme }) => (theme.fonts.sizes.xl)};
    }
  }

  p {
    font-size: ${({ theme }) => (theme.fonts.sizes.l)};
    text-align: justify;

    @media only screen and (max-width: ${BREAKPOINTS.SMALLER_FONT_SIZE}) {
      font-size: ${({ theme }) => (theme.fonts.sizes.m)};
    }
  }

  ul {
    list-style-type: none;
  }

  li {
    list-style-position: inside;
    font-size: ${({ theme }) => (theme.fonts.sizes.l)};

    @media only screen and (max-width: ${BREAKPOINTS.SMALLER_FONT_SIZE}) {
      font-size: ${({ theme }) => (theme.fonts.sizes.m)};
    }
  }

  a {
    display: flex;
    color: inherit;
    text-decoration: none;
    align-items: center;
    ${LinkTransitions}
    font-size: ${({ theme }) => (theme.fonts.sizes.xl)};

    @media only screen and (max-width: ${BREAKPOINTS.SMALLER_FONT_SIZE}) {
      font-size: ${({ theme }) => (theme.fonts.sizes.l)};
    }
  }

  svg {
    height: 100%;
    width: auto;
    display: flex;
    transform: translateZ(0);
  }

  button {
    border: none;
    font-family: inherit;
    cursor: pointer;
    ${LinkTransitions}
  }
`;

export default GlobalStyles;
