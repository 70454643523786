interface Consent {
  necessary: boolean,
  analytics: boolean,
  preferences: boolean,
  marketing: boolean,
}

const setConsentMode = (consent: Consent, test?: boolean) => {
  const consentMode = {
    functionality_storage: consent.necessary ? 'granted' : 'denied',
    security_storage: consent.necessary ? 'granted' : 'denied',
    analytics_storage: consent.analytics ? 'granted' : 'denied',
    personalization_storage: consent.preferences ? 'granted' : 'denied',
    ad_storage: consent.marketing ? 'granted' : 'denied',
  };

  if (!test) window.gtag('consent', 'update', consentMode);
  localStorage.setItem('consentMode', JSON.stringify(consentMode));
};

export default setConsentMode;
