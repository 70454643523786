import {
  createContext,
  useMemo,
  useState,
  PropsWithChildren,
} from 'react';

interface ErrorContextProps {
  hasError: boolean,
  setHasError: React.Dispatch<React.SetStateAction<boolean>>,
}

export const ErrorContext = createContext<ErrorContextProps>({
  hasError: true,
  setHasError: () => null,
});

export const ErrorHandler = ({ children }: PropsWithChildren) => {
  const [hasError, setHasError] = useState(true);
  const value = useMemo(() => ({ hasError, setHasError }), [hasError]);

  return (
    <ErrorContext.Provider value={value}>
      {children}
    </ErrorContext.Provider>
  );
};
