import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  PropsWithChildren,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import Checkbox from 'styles/Checkbox';
import { PATHNAMES } from 'utils/constants';
import CloseIcon from 'components/CloseIcon';
import setConsentMode from './setConsentMode';
import {
  StyledBannerCookiesConsentBannerBackground,
  StyledCookiesConsentBanner,
  StyledClose,
  StyledButtons,
  StyledPrimaryButton,
  Spacer,
  StyledSecondaryButton,
} from './styled';

interface CookiesConsentContextProps {
  forceShow: boolean,
  setForceShow: React.Dispatch<React.SetStateAction<boolean>>,
}

export const CookiesConsentContext = createContext<CookiesConsentContextProps>({
  forceShow: false,
  setForceShow: () => null,
});

export const CookiesConsentHandler = ({ children }: PropsWithChildren) => {
  const [forceShow, setForceShow] = useState(false);
  const value = useMemo(() => ({ forceShow, setForceShow }), [forceShow]);

  return (
    <CookiesConsentContext.Provider value={value}>
      {children}
    </CookiesConsentContext.Provider>
  );
};

const CookiesConsentBanner = ({ test }: { test?: boolean }) => {
  const [consentNecessary, setConsentNecessary] = useState(true);
  const [consentAnalytics, setConsentAnalytics] = useState(true);
  const [consentPreferences, setConsentPreferences] = useState(true);
  const [consentMarketing, setConsentMarketing] = useState(false);
  const [isClosed, setIsClosed] = useState(true);
  const { pathname } = useLocation();
  const {
    forceShow,
    setForceShow,
  } = useContext(CookiesConsentContext);

  const close = () => {
    setIsClosed(true);
    setForceShow(false);
  };

  const syncWithLocalStorage = () => {
    const storedConsentMode = localStorage.getItem('consentMode');
    if (!storedConsentMode) return;

    let parsedConsentMode;
    try {
      parsedConsentMode = JSON.parse(storedConsentMode);
    } catch {
      parsedConsentMode = {};
    }

    const {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ad_storage,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      analytics_storage,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      personalization_storage,
    } = parsedConsentMode;

    setConsentNecessary(true);
    setConsentAnalytics(analytics_storage ? analytics_storage === 'granted' : true);
    setConsentPreferences(personalization_storage ? personalization_storage === 'granted' : true);
    setConsentMarketing(ad_storage ? ad_storage === 'granted' : false);
  };

  useEffect(() => {
    if (
      localStorage.getItem('consentMode')
      || (PATHNAMES.CookiesPolicy === pathname)
      || (PATHNAMES.PrivacyPolicy === pathname)
    ) {
      setIsClosed(true);
      return;
    }

    setIsClosed(false);
  }, [pathname]);

  useEffect(() => {
    if (forceShow) {
      if (isClosed) {
        syncWithLocalStorage();
      }
      setIsClosed(false);
    }
  }, [forceShow]);

  const onChangeNecessary = () => (
    setConsentNecessary(true)
  );

  const onChangeAnalytics = () => (
    setConsentAnalytics(!consentAnalytics)
  );

  const onChangePreferences = () => (
    setConsentPreferences(!consentPreferences)
  );

  const onChangeMarketing = () => (
    setConsentMarketing(!consentMarketing)
  );

  const acceptAll = () => {
    setConsentMode({
      necessary: true,
      analytics: true,
      preferences: true,
      marketing: true,
    }, test);

    close();
  };

  const acceptSelection = () => {
    setConsentMode({
      necessary: consentNecessary,
      analytics: consentAnalytics,
      preferences: consentPreferences,
      marketing: consentMarketing,
    }, test);

    close();
  };

  const cookieAlternatives = [
    {
      id: 'consent-necessary',
      value: 'Necessary',
      checked: consentNecessary,
      disabled: true,
      onChange: onChangeNecessary,
    },
    {
      id: 'consent-analytics',
      value: 'Analytics',
      checked: consentAnalytics,
      disabled: false,
      onChange: onChangeAnalytics,
    },
    {
      id: 'consent-preferences',
      value: 'Preferences',
      checked: consentPreferences,
      disabled: false,
      onChange: onChangePreferences,
    },
    {
      id: 'consent-marketing',
      value: 'Marketing',
      checked: consentMarketing,
      disabled: false,
      onChange: onChangeMarketing,
    },
  ];

  if (isClosed) {
    return null;
  }

  return (
    <>
      <StyledBannerCookiesConsentBannerBackground />
      <StyledCookiesConsentBanner data-jf="cookies-consent-banner">
        {
          forceShow && (
            <StyledClose onClick={close} data-jf="cookies-consent-banner-close-button">
              <CloseIcon />
            </StyledClose>
          )
        }
        <h2>Cookies</h2>
        <p>
          By clicking ”Accept all”, you agree that Joel Forsberg can store cookies on your device
          and disclose information in accordance with the&nbsp;
          <Link to={PATHNAMES.CookiesPolicy} target="_blank">cookies policy</Link>
          .
        </p>
        <ul>
          {
            cookieAlternatives.map((cookieAlternative) => (
              <li key={cookieAlternative.id}>
                <label htmlFor={cookieAlternative.id}>
                  <Checkbox
                    id={cookieAlternative.id}
                    type="checkbox"
                    value={cookieAlternative.value}
                    checked={cookieAlternative.checked}
                    onChange={cookieAlternative.onChange}
                    disabled={cookieAlternative.disabled}
                  />
                  <span>{cookieAlternative.value}</span>
                </label>
                <br />
              </li>
            ))
          }
        </ul>
        <StyledButtons>
          <StyledPrimaryButton
            type="button"
            onClick={acceptAll}
            data-jf="cookies-consent-banner-accept-all-button"
          >
            Accept all
          </StyledPrimaryButton>
          <Spacer />
          <StyledSecondaryButton
            type="button"
            onClick={acceptSelection}
            data-jf="cookies-consent-banner-accept-selection-button"
          >
            Accept selection
          </StyledSecondaryButton>
        </StyledButtons>
      </StyledCookiesConsentBanner>
    </>
  );
};

CookiesConsentBanner.defaultProps = {
  test: false,
};

export default CookiesConsentBanner;
