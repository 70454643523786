import { SERVICES } from 'utils/constants';
import Svg from 'components/Svg';

const Mixcloud = () => (
  <Svg
    viewBox="0 0 100 100"
    title={SERVICES.Mixcloud}
    fontSize="1.8em"
  >
    <path
      d="M50 3.1391e-10C43.4339 -2.32658e-05 36.9321 1.29326 30.8658 3.80602C24.7995 6.31877 19.2875 10.0018 14.6446 14.6448C10.0017 19.2878 6.3187 24.7998 3.80597 30.8661C1.29325 36.9325 -2.42993e-05 43.4344 3.42422e-10 50.0005C0.000234931 63.2613 5.26819 75.9789 14.645 85.3556C24.0218 94.7323 36.7393 100 50 100C63.2607 100 75.9782 94.7323 85.355 85.3556C94.7318 75.9789 99.9998 63.2613 100 50.0005C100 43.4344 98.7068 36.9325 96.194 30.8661C93.6813 24.7998 89.9983 19.2878 85.3554 14.6448C80.7125 10.0018 75.2005 6.31877 69.1342 3.80602C63.0679 1.29326 56.5661 -2.32658e-05 50 3.13911e-10V3.1391e-10ZM23.9843 27.8215H40.0243L48.9622 70.1093H51.0389L59.9757 27.8215H76.0169V72.1785H67.9069V35.1768L69.4152 29.8907H66.8002L57.4844 72.1785H42.5791L33.2009 29.8907H30.6472L32.0931 35.1768V72.1785H23.9843V27.8215Z"
      fill="currentColor"
    />
  </Svg>
);

export default Mixcloud;
