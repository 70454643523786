import { lazy } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { withSuspense } from 'utils/helpers';
import Layout from 'pages/Layout';
import { PATHNAMES } from 'utils/constants';

const CookiesPolicy = lazy(() => import('pages/CookiesPolicy'));
const Home = lazy(() => import('pages/Home'));
const MailingList = lazy(() => import('pages/MailingList'));
const Music = lazy(() => import('pages/Music'));
const NotFound = lazy(() => import('pages/NotFound'));
const PrivacyPolicy = lazy(() => import('pages/PrivacyPolicy'));
const ReleaseDetails = lazy(() => import('pages/ReleaseDetails'));

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index element={withSuspense(<Home documentTitle="Joel Forsberg" />)} />
      <Route path={PATHNAMES.Music} element={withSuspense(<Music documentTitle="Music - Joel Forsberg" />)} />
      <Route path={`${PATHNAMES.Music}/:slug`} element={withSuspense(<ReleaseDetails />)} />
      <Route path={`${PATHNAMES.MusicLandingPage}/:slug`} element={withSuspense(<ReleaseDetails landingPage />)} />
      <Route path={PATHNAMES.MailingList} element={withSuspense(<MailingList documentTitle="Mailing list - Joel Forsberg" />)} />
      <Route path={PATHNAMES.CookiesPolicy} element={withSuspense(<CookiesPolicy documentTitle="Cookies policy - Joel Forsberg" />)} />
      <Route path={PATHNAMES.PrivacyPolicy} element={withSuspense(<PrivacyPolicy documentTitle="Privacy policy - Joel Forsberg" />)} />
      <Route path="*" element={withSuspense(<NotFound documentTitle="404 Not Found - Joel Forsberg" />)} />
    </Route>,
  ),
);

const Router = () => (
  <RouterProvider router={router} />
);

export default Router;
