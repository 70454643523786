import { SERVICES } from 'utils/constants';
import Svg from 'components/Svg';

const YouTube = () => (
  <Svg
    viewBox="0 0 100 100"
    title={SERVICES.YouTube}
    fontSize="1.8em"
  >
    <path
      d="M50.0006 3.81355e-10C43.4344 -2.56435e-05 36.9325 1.29324 30.8661 3.80597C24.7998 6.3187 19.2878 10.0017 14.6448 14.6446C10.0018 19.2875 6.31877 24.7995 3.80602 30.8658C1.29326 36.9321 -2.3576e-05 43.4339 3.51233e-10 50C-2.461e-05 56.5661 1.29326 63.0679 3.80602 69.1342C6.31877 75.2005 10.0018 80.7125 14.6448 85.3554C19.2878 89.9983 24.7998 93.6813 30.8661 96.194C36.9325 98.7068 43.4344 100 50.0006 100C63.2614 99.9998 75.9789 94.7318 85.3556 85.355C94.7323 75.9782 100 63.2606 100 50C100 36.7393 94.7323 24.0218 85.3556 14.645C75.9789 5.2682 63.2614 0.000244746 50.0006 3.81358e-10V3.81355e-10ZM39.3616 34.003L67.253 50L39.3616 65.9959V34.003Z"
      fill="currentColor"
    />
  </Svg>
);

export default YouTube;
