import { ApolloProvider } from '@apollo/client';
import { HelmetProvider } from 'react-helmet-async';
import client from 'api/client';
import { ErrorHandler } from 'components/Error';
import { LoadingHandler } from 'components/Loading';
import { CookiesConsentHandler } from 'components/CookiesConsentBanner';
import Router from 'components/Router';
import Styled from 'components/Styled';

const App = () => (
  <ApolloProvider client={client}>
    <HelmetProvider>
      <Styled>
        <ErrorHandler>
          <LoadingHandler>
            <CookiesConsentHandler>
              <Router />
            </CookiesConsentHandler>
          </LoadingHandler>
        </ErrorHandler>
      </Styled>
    </HelmetProvider>
  </ApolloProvider>
);

export default App;
